// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import 'admin-lte/dist/css/adminlte.min.css'
import 'admin-lte/dist/js/adminlte.min.js'
import '../stylesheets/application.scss';


import $ from 'jquery';
window.jQuery = $;
window.$ = $;

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// Charting libraries
import Chart from 'chart.js/auto';
import Chartkick from 'chartkick';
import 'chartjs-adapter-date-fns'; 
import 'select2'

// Associate Chartkick with Chart.js
Chartkick.addAdapter(Chart);

// $(document).on('turbolinks:load', function() {
//   $('#customer_id').select2({
//     placeholder: 'Select a customer',
//     allowClear: true,
//     width: '100%'
//   });
// });